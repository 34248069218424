import React from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";

const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <BootstrapPagination className="mt-3">
      <BootstrapPagination.First
        onClick={() => paginate(1)}
        disabled={currentPage === 1}
      />
      <BootstrapPagination.Prev
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
      />

      {pageNumbers.map((number) => (
        <BootstrapPagination.Item
          key={number}
          onClick={() => paginate(number)}
          active={currentPage === number}
        >
          {number}
        </BootstrapPagination.Item>
      ))}

      <BootstrapPagination.Next
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === pageNumbers.length}
      />
      <BootstrapPagination.Last
        onClick={() => paginate(pageNumbers.length)}
        disabled={currentPage === pageNumbers.length}
      />
    </BootstrapPagination>
  );
};

export default Pagination;
