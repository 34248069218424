import React, { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom"; // Import useLocation for active link detection

// Styled Components
const SubSidebarContainer = styled.div`
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-right: 1px solid #ddd; /* Right-side border for a professional look */
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc; /* Adding a border to define the search box */
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: #6c757d;
  }
`;

const NavMenuContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavLink = styled(Link)`
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: 10px;

  &:hover {
    background-color: #e9ecef;
    color: #007bff;
  }

  &.active {
    background-color: #007bff;
    color: #fff;
  }
`;

const SubSidebar = ({ navigationItems = []}) => {
  const location = useLocation(); // Get the current location

  

  const [searchQuery, setSearchQuery] = useState("");

  // Filter navigation items based on the search query
  const filteredItems = navigationItems.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <SubSidebarContainer>
      {/* Search Box */}
      <SearchBox>
        <SearchInput
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchBox>

      {/* Navigation Menu */}
      <NavMenuContainer>
        <NavMenu>
          {filteredItems.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={location.pathname.includes(item.path) ? "active" : ""}
            >
              {item.label}
            </NavLink>
          ))}
        </NavMenu>
      </NavMenuContainer>
    </SubSidebarContainer>
  );
};

export default SubSidebar;
