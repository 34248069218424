// src/utils/axiosInstance.js
import axios from "axios";
import AuthService from "../services/authService";

const baseURLs = {
  local: "http://localhost:3000/api",
  web: "http://api.kaaryaalaya.com/api/",
};

// Set the environment; you can switch between 'local' and 'web'
const environment = process.env.NODE_ENV === "production" ? "web" : "local";

const axiosInstance = axios.create({
  baseURL: baseURLs[environment], // Dynamically set the base URL based on the environment
});

// Add a request interceptor to include the token in all requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken(); // Retrieve the token from AuthService
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Add the token to the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
