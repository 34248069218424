import DashboardLayout from "./DashboardLayout";



function SettingLayout({children}) {
  // Navigation items (sample data)
  const navigationItems = [
    { label: "Positions", path: "/settings/positions" },
    { label: "Courses", path: "/settings/courses" },
    { label: "Subjects", path: "/settings/subjects" },
    { label: "Class Rooms", path: "/settings/classrooms" },
  ];

  return (
    <DashboardLayout navigationItems={navigationItems}>{children}</DashboardLayout>
  );
}

export default SettingLayout;