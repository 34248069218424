// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// src/index.js or src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Positions from "./pages/Positions";
import Courses from "./pages/Courses";
import Subjects from "./pages/Subjects";
import ClassRooms from "./pages/ClassRooms";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/positions" element={<Positions />} />
        <Route path="settings/courses" element={<Courses />} />
        <Route path="settings/subjects" element={<Subjects />} />
        <Route path="settings/classrooms" element={<ClassRooms />} />
      </Routes>
    </Router>
  );
}

export default App;
