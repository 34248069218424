import styled from "styled-components";
import Sidebar from "../Components/Sidebar";
import SubSidebar from "../Components/SubSidebar";
import ContentContainer from "../pages/ContentContainer";

// Define a parent container that uses flexbox to layout the sidebars and content
const AppContainer = styled.div`
  display: flex;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Prevent the entire page from scrolling */
`;


function DashboardLayout({ children, navigationItems = [] }) {
  return (
    <AppContainer>
      <Sidebar />
      <SubSidebar navigationItems={navigationItems} />
      <ContentContainer>{children}</ContentContainer>
    </AppContainer>
  );
}

export default DashboardLayout;