import React, { useEffect } from "react";
import DashboardLayout from "../Layout/DashboardLayout";
import useAuth from "../utils/useAuth";


const Dashboard = () => {
    useAuth();
  return (
    <DashboardLayout>
        hi
    </DashboardLayout>
  );
};

export default Dashboard;
