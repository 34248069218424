// src/pages/Login.js
import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import Joi from "joi";
import axiosInstance from "../utils/axiosInstance";
import AuthService from "../services/authService"; // Ensure correct import
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const LoginCard = styled.div`
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 400px;
`;

const IconWrapper = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
`;

const StyledFeedback = styled(Form.Control.Feedback)`
  width: 100%;
  min-height: 1.5em;
`;

function Login() {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const schema = Joi.object({
    username: Joi.string().min(3).max(30).required().label("Username"),
    password: Joi.string().min(6).required().label("Password"),
  });

  const validateField = (name, value) => {
    const fieldSchema = Joi.object({ [name]: schema.extract(name) });
    const { error } = fieldSchema.validate({ [name]: value });
    return error ? error.details[0].message : null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (touched[name]) {
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched({ ...touched, [name]: true });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const errorObj = {};
    error.details.forEach((detail) => {
      errorObj[detail.path[0]] = detail.message;
    });

    return errorObj;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (validationErrors) {
      setErrors(validationErrors);
      setTouched({ username: true, password: true });
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.post("/super-admin/login", formData);

      // Store the token and user information using AuthService
      AuthService.setToken(response.data.token);
      AuthService.setUserInfo(response.data.superAdmin);

      // console.log("Login successful", response.data);

      // Redirect to the dashboard or any other page
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed", error.response?.data || error.message);
      setErrors({
        general: "Login failed. Please check your credentials and try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CenteredContainer>
      <LoginCard>
        <h3 className="mb-4">Login</h3>
        {errors.general && (
          <p style={{ color: "red", fontWeight: "bold" }}>{errors.general}</p>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.username}
            />
            <StyledFeedback type="invalid">{errors.username}</StyledFeedback>
          </Form.Group>

          <Form.Group
            controlId="formPassword"
            className="mt-3"
            style={{ position: "relative" }}
          >
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.password}
              />
              <IconWrapper onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </IconWrapper>
              <StyledFeedback type="invalid">{errors.password}</StyledFeedback>
            </InputGroup>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="mt-4"
            block
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </Button>
        </Form>
      </LoginCard>
    </CenteredContainer>
  );
}

export default Login;
