import React from 'react';
import styled from 'styled-components';

// Styled Components
const ContentContainerStyle = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;

  /* Custom Scrollbar Styling */
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
    border: 2px solid #f1f1f1; /* Padding around thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Hover color of the scrollbar thumb */
  }
`;

const ContentContainer = ({ children }) => {
  return <ContentContainerStyle>{children}</ContentContainerStyle>;
};

export default ContentContainer;
