// src/services/authService.js

class AuthService {
  // Store the token in localStorage
  static setToken(token) {
    localStorage.setItem('token', token);
  }

  // Retrieve the token from localStorage
  static getToken() {
    return localStorage.getItem('token');
  }

  // Store user information in localStorage
  static setUserInfo(superAdmin) {
    localStorage.setItem('superAdmin', JSON.stringify(superAdmin));
  }

  // Retrieve user information from localStorage
  static getUserInfo() {
    const superAdmin = localStorage.getItem('superAdmin');
    return superAdmin ? JSON.parse(superAdmin) : null;
  }

  // Clear token and user information from localStorage
  static clearAuth() {
    localStorage.removeItem('token');
    localStorage.removeItem('superAdmin');
  }
}

export default AuthService;
